import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';
import MainView from '../views/MainView.vue'
import AuthView from '../views/AuthView.vue'
import LoginPage from '../components/LoginPage.vue'
import Dashboard from '../components/Parents/Dashboard.vue'
import ErrorNotFound from '../components/Utils/ErrorNotFound.vue';
import Products from '../components/Parents/Products.vue';
import PointsChecker from '../components/Parents/PointsChecker.vue';
import Purchases from '../components/Parents/Purchases.vue';
import Giveaways from '../components/Parents/Giveaways.vue';
import Challenges from '../components/Parents/Challenges.vue';
import Roullete from '../components/Parents/Roullete.vue';
import AccountVerification from '../components/Parents/AccountVerification.vue';

const routes = [
  {
    path: '/',
    component: AuthView,
    meta: { alreadyAuth: true },
    children: [
      {
        path: '/',
        name: 'login',
        component: LoginPage,
      },
      // {
      //   path: '/register',
      //   name: 'register',
      //   component: RegisterPage,
      // },
    ]
  },

  {
    path: '/admin',
    name: 'admin',
    meta: { routeForAdmin: true },
    component: MainView,
    children: [
      {
        path: '/admin/dashboard',
        component: Dashboard,
        meta: {
          title: 'Dashboard'
        }
      },
      {
        path: '/admin/challenges',
        component: Challenges,
        meta: {
          title: 'Challenges'
        }
      },
      {
        path: '/admin/account-verification',
        component: AccountVerification,
        meta: {
          title: 'Account Verification'
        }
      },
      {
        path: '/admin/points-checker',
        component: PointsChecker,
        meta: {
          title: 'Points Checker'
        }
      },
      {
        path: '/admin/products',
        component: Products,
        meta: {
          title: 'Products'
        }
      },
      {
        path: '/admin/purchases',
        component: Purchases,
        meta: {
          title: 'Purchases'
        }
      },
    ]
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'ErrorView',
    component: ErrorNotFound,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  let user = store.state.user;

  if (to.matched.some(rec => rec.meta.alreadyAuth)) {
      if (user) {
          next('/admin/dashboard');
      } else {
          next();
      }
  } else if (to.matched.some(record => record.meta.routeForAdmin)) {
      if (!user) {
          next('/');
      } else {
          next();
      }
  }
  else {
      next();
  }
});

export default router
