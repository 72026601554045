<template>
    <v-dialog v-model="dialog" width="800" persistent>
        <v-card class="rounded-lg" style="display: flex; flex-direction: column; height: 100%;">
            <v-card-title style="background-color: #272C30 !important; display: flex; justify-content: space-between; align-items: center; color: white;">
                <div>
                    <span class="font-weight-bold text-h6" style="font-family: Saira Condensed, sans-serif;">{{ title }}</span>
                    <div class="text-caption" style="font-family: Saira Condensed, sans-serif;">{{ sub_title }}</div>
                </div>
                <v-btn :disabled="loading" flat icon color="primary" @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="overflow-y: auto; max-height: calc(100vh - 200px);">
                <v-form ref="form" class="mt-4">
                    <v-row dense>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="status"
                                :rules="[formRules.required]"
                                label="Challenge Status"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                                :items="statusData"
                                item-title="title"
                                item-value="value"
                            ></v-select>
                        </v-col>
                        <v-col v-if="status == 'completed'" cols="12" md="6">
                            <v-text-field
                                v-model="completedBy"
                                :rules="[formRules.required, formRules.noSpacesOnly]"
                                label="Completed By"
                                type="text"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="c_title"
                                :rules="[formRules.required, formRules.noSpacesOnly]"
                                label="Title"
                                type="text"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="description"
                                :rules="[formRules.required, formRules.noSpacesOnly]"
                                label="Description"
                                type="text"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="reward"
                                :rules="[formRules.required, formRules.maxLength, formRules.minValue]"
                                label="Reward"
                                type="number"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="minimumBet"
                                :rules="[formRules.required, formRules.maxLength, formRules.minValue]"
                                label="Minimum Bet"
                                type="number"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="challengeType"
                                :rules="[formRules.required]"
                                label="Challenge Category"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                                :items="challengeTypeData"
                                item-title="title"
                                item-value="value"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="challengeUrl"
                                :rules="[formRules.required, formRules.noSpacesOnly]"
                                label="URL"
                                type="text"
                                hint="https://roobet.com/game/pushgaming:RetroSweets"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                            ></v-text-field>
                        </v-col>
                        <v-col v-show="imgChange == true" cols="12">
                            <v-text-field
                                v-model="imageUrl"
                                :rules="[formRules.required, formRules.noSpacesOnly]"
                                label="Image URL"
                                type="text"
                                hint="https://fastly.picsum.photos/id/3/5000/3333.jpg?hmac=GDjZ2uNWE3V59PkdDaOzTOuV3tPWWxJSf4fNcxu4S2g"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                            ></v-text-field>
                        </v-col>
                        <!-- <v-col v-if="imgChange == true" cols="12">
                            <v-file-input
                                v-model="file"
                                :rules="[formRules.required, formRules.fileSize]"
                                accept="image/*"
                                label="Image File"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                show-size
                                style="font-family: Saira Condensed, sans-serif;"
                            ></v-file-input>
                        </v-col> -->
                        <v-col v-show="imgChange == false" cols="12" md="6">
                            <v-img
                            class="mx-auto"
                            rounded="lg"
                            :src="imageUrl"
                            lazy-src="https://picsum.photos/id/11/100/60"
                            loading>
                            <template v-slot:placeholder>
                                <div class="d-flex align-center justify-center fill-height">
                                    <v-progress-circular
                                    color="grey-lighten-4"
                                    indeterminate
                                    ></v-progress-circular>
                                </div>
                            </template>
                            </v-img>
                        </v-col>
                        <v-col v-if="imgChange == false" cols="12" md="6">
                            <v-btn @click="imgChange = true" class="mr-1 text-subtitle-1" text="Change Image" variant="elevated" elevation="1" color="primary" style="font-family: Saira Condensed, sans-serif;">
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-3" style="margin-top: auto;">
                <v-spacer></v-spacer>
                <v-btn class="mr-1 text-subtitle-1" :disabled="loading" text="Cancel" variant="outlined" elevation="1" @click="closeDialog()" style="font-family: Saira Condensed, sans-serif;"></v-btn>
                <v-btn class="mr-1 text-subtitle-1" :loading="loading" text="Save" variant="elevated" elevation="1" color="primary" @click="validateForm()" style="font-family: Saira Condensed, sans-serif;"></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        :timeout="2500"
        color="secondary"
        location="top">
        <v-icon>mdi-alert-circle</v-icon>
        <span class="ml-2" style="font-family: Saira Condensed, sans-serif;">{{ snackText }}</span>
    </v-snackbar>
</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue'

export default defineComponent({
     emits: ["refresh"],
     data(){
        return {
            dialog: false,
            title: null,
            sub_title: null,
            action: null,
            loading: false,
            
            snackbar: false,
            snackText: null,

            id: null,
            c_title: null,
            description: null,
            reward: null,
            challengeUrl: null,
            minimumBet: null,
            challengeType: null,
            completedBy:null,
            file: null,
            imageUrl: null,
            status: null,

            imgChange: true,

            challengeTypeData: [
                { title: 'Roobet', value: 'roobet'},
                { title: 'Chicken.GG', value: 'chicken.gg'}
            ],

            statusData: [
                { title: 'Active', value: 'active'},
                { title: 'Completed', value: 'completed'},
            ],

            formRules: {
                required: value => !!value || 'Required',
                minValue: value => (value && value != 0) || 'Must be greater than 0',
                maxLength: value => (value && value.toString().length <= 10) || 'Must be less than or equal to 10 characters',
                noSpacesOnly: value => (value && value.trim().length > 0) || 'This cannot be just spaces',
                fileSize: value => {
                    if (value[0]){
                        return value[0].size <= 2 * 1024 * 1024 || 'File size must be less than 2MB';
                    }
                }
            }
        }
     },
     methods: {
        initAdd(){
            this.dialog = true
            this.title = 'Add Challenges'
            this.sub_title = 'Ballyboy challenges information'
            this.action = 'add'

            this.status = 'active'
            this.imgChange = true
        },
        initUpdate(data){
            this.dialog = true
            this.title = 'Edit Challenges'
            this.sub_title = 'Ballyboy challenges information'
            this.action = 'edit'

            this.id = data._id
            this.c_title = data.title
            this.description = data.description
            this.reward = data.reward
            this.challengeUrl = data.challengeUrl
            this.completedBy = data.completedBy
            this.imageUrl = data.imageUrl
            this.minimumBet = data.minimumBet
            this.challengeType = data.challengeType
            if(this.imageUrl){
                this.imgChange = false
            }
            else{
                this.imgChange = true
            }
            this.status = data.status
        },
        async validateForm(){
            const { valid } = await this.$refs.form.validate()

            if(valid){
                const token = localStorage.getItem('ballyboy-admin-accesstoken')
                
                let formData = {
                    id: this.id,
                    title: this.c_title,
                    description: this.description,
                    reward: this.reward,
                    imageUrl: this.imageUrl,
                    minimumBet: this.minimumBet,
                    challengeType: this.challengeType,
                    challengeUrl: this.challengeUrl,
                    completedBy: this.completedBy,
                    status: this.status
                }
                this.loading = true

                if(this.action == 'add'){
                    axios.post(process.env.VUE_APP_SERVER_URL + '/api/admin/challenge/add', formData,
                    { headers:
                        { 
                            'Authorization': `Bearer ${token}`,
                            'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                        }
                    }
                    ).then((res)=>{
                        if(res.data.status == 200){
                            this.$emit('refresh')
                            this.loading = false
                            this.snackText = 'Challenges Successfully Added!'
                            this.snackbar = true
                            this.$refs.form.reset()
                            this.dialog = false
                        }
                    })
                }
                else if(this.action == 'edit'){
                    axios.put(process.env.VUE_APP_SERVER_URL + '/api/admin/challenge/update', formData,
                    { headers:
                        { 
                            'Authorization': `Bearer ${token}`,
                            'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                        }
                    }
                    ).then((res)=>{
                        if(res.data.message){
                            this.$emit('refresh')
                            this.loading = false
                            this.snackText = 'Challenges Successfully Updated!'
                            this.snackbar = true
                            this.$refs.form.reset()
                            this.dialog = false
                        }
                    })
                }
            }
        },
        closeDialog(){
            this.$refs.form.reset()
            this.dialog = false
        }
     }
})
</script>

<style scoped>

</style>