<template>
    <v-dialog v-model="dialog" width="800" persistent>
        <v-card class="rounded-lg" style="display: flex; flex-direction: column; height: 100%;">
            <v-card-title style="background-color: #121212 !important; display: flex; justify-content: space-between; align-items: center; color: white;">
                <div>
                    <span class="font-weight-bold text-h6" style="font-family: Saira Condensed, sans-serif;">{{ main_title }}</span>
                    <div class="text-caption" style="font-family: Saira Condensed, sans-serif;">{{ sub_title }}</div>
                </div>
                <v-btn :disabled="loading" flat icon color="primary" @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="overflow-y: auto; max-height: calc(100vh - 200px);">
                <v-form ref="form" class="mt-4">
                    <v-row dense>
                        <v-col cols="12" md="3">
                            <v-select
                            v-model="status"
                            variant="outlined"
                            label="Status"
                            density="comfortable"
                            hide-details
                            class="rounded-lg"
                            :items="status == 'refunded' ? refundedStatusData : statusData"
                            item-title="title"
                            item-value="value"
                            style="font-family: Saira Condensed, sans-serif;"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row class="mt-4" dense style="font-family: Saira Condensed, sans-serif;">
                        <v-col cols="12" md="6">
                            <div class="font-weight-bold">Ticket:</div>
                            <div>{{ data.ticketNumber }}</div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="font-weight-bold">Points Spend:</div>
                            <div>{{ data.amount }}</div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="font-weight-bold">Product:</div>
                            <div>{{ data.product.title }}</div>
                            <div>{{ data.product.description }}</div>
                            <div>{{ data.product.rewardsCondition }}</div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="font-weight-bold">User Information:</div>
                            <div>Discord Usename: {{ data.user.username }} ({{ 'ID:' + data.user.discordId }})</div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="font-weight-bold">Date Purchased:</div>
                            <div>{{ formatDateTime2(data.createdAt) }}</div>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-3" style="margin-top: auto;">
                <v-spacer></v-spacer>
                <v-btn class="mr-1 text-subtitle-1" :disabled="loading" text="Cancel" variant="outlined" elevation="1" @click="closeDialog()" style="font-family: Saira Condensed, sans-serif;"></v-btn>
                <v-btn class="mr-1 text-subtitle-1" :loading="loading" text="Save" variant="elevated" elevation="1" color="primary" @click="validateForm()" style="font-family: Saira Condensed, sans-serif;"></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        :timeout="2500"
        color="secondary"
        location="top">
        <v-icon>mdi-alert-circle</v-icon>
        <span class="ml-2" style="font-family: Saira Condensed, sans-serif;">{{ snackText }}</span>
    </v-snackbar>
</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue'

export default defineComponent({
     emits: ["refresh"],
     data(){
        return {
            dialog: false,
            main_title: null,
            sub_title: null,
            action: null,
            loading: false,
            
            snackbar: false,
            snackText: null,

            id: null,
            data: [],
            status: null,

            statusData: [
                { title: 'Pending', value: 'pending' },
                { title: 'Inprogress', value: 'inprogress' },
                { title: 'Completed', value: 'completed' },
                { title: 'Declined', value: 'declined' },
            ],

            refundedStatusData: [
                { title: 'Refunded', value: 'refunded' },
            ],

            formRules: {
                required: value => !!value || 'Required',
                minValue: value => (value && value != 0) || 'Must be greater than 0',
                maxLength: value => (value && value.toString().length <= 10) || 'Must be less than or equal to 10 characters',
                noSpacesOnly: value => (value && value.trim().length > 0) || 'This cannot be just spaces',
            }
        }
     },
     methods: {
        initView(data){
            this.dialog = true
            this.main_title = 'View Purchase'
            this.sub_title = 'User purchased information'
            this.action = 'view'

            this.id = data._id
            this.data = data
            this.status = data.status
        },
        async validateForm(){
            const { valid } = await this.$refs.form.validate()

            if(valid){
                const token = localStorage.getItem('seham-admin-accesstoken')
                
                let formData = {
                    status: this.status
                }

                this.loading = true
                if(this.action == 'view'){
                    axios.patch(process.env.VUE_APP_SERVER_URL + '/api/admin/store/purchase/status/' + this.id, formData,
                    { headers:
                        { 
                            'Authorization': `Bearer ${token}`,
                            'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                        }
                    }
                    ).then((res)=>{
                        if(res.data){
                            this.$emit('refresh')
                            this.loading = false
                            this.snackText = 'Purchased status updated successfully'
                            this.snackbar = true
                            this.$refs.form.reset()
                            this.dialog = false
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                    localStorage.removeItem("vuex");
                                    localStorage.removeItem("seham-admin-accesstoken");
                                    window.location.reload();
                            } else {
                                console.log(`Error: ${error.response.status}`);
                            }
                        } 
                        else if (error.request) {
                            console.log('No response received', error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                    });
                }
            }
        },
        closeDialog(){
            this.$refs.form.reset()
            this.dialog = false
        }
     }
})
</script>

<style scoped>

</style>