<template>
    <v-dialog v-model="dialog" fullscreen>
        <v-card class="rounded-lg" style="display: flex; flex-direction: column; height: 100%;">
            <v-card-title style="background-color: #272C30 !important; display: flex; justify-content: space-between; align-items: center; color: white;">
                <div>
                    <span class="font-weight-bold text-h6" style="font-family: Saira Condensed, sans-serif;">SPIN THE WHEEL</span>
                    <div class="text-caption" style="font-family: Saira Condensed, sans-serif;">Ballyboy Giveaway</div>
                </div>
                <v-btn flat icon color="primary" @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="overflow-y: auto; background-color: #272C30 !important;">
                <!-- Please input the roullet here -->
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    emits: ["refresh"],
    data () {
        return {
            dialog: false
        }
    },
    methods: {
        initWheels(){
            this.dialog = true
        }
    }
})
</script>

<style scoped>

</style>