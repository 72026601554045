<template>
    <section class="login-bg">
        <v-container>
            <v-row class="d-flex justify-center align-center mt-16">
                <v-col cols="12" md="4">
                    <div class="d-flex flex-column align-center mt-6">
                        <v-img class="w-50 ml-n1" src="../assets/seham-logo.png"></v-img>
                        <div class="text-h5 mx-4" style="font-family: Angkor, serif; color: #FFFFFF">Welcome Admin</div>
                        <div class="mt-4" style="font-family: Angkor, serif; color: #FFFFFF">Sign in to continue access</div>
                    </div>
                </v-col>
                <v-col cols="12" md="5" class="mt-10">
                    <v-form ref="Formref">
                        <v-card height="350" rounded="xl" elevation="6" color="primary" class="card-bg d-flex">
                            <v-container>
                                <v-row dense>
                                    <v-col cols="12" class="mt-16 px-8">
                                        <div class="d-flex">
                                            <div class="mt-2 mr-4 text-h6" style="font-family: Saira Condensed, sans-serif; color: #0FAF79;">USERNAME:</div>
                                            <v-text-field
                                            v-model="username"
                                            :rules="[formRules.required]"
                                            variant="solo"
                                            bg-color="#FFFFFF"
                                            type="email"
                                            class="text-h6"
                                            density="compact"
                                            style="font-family: Saira Condensed, sans-serif;"
                                            >
                                            </v-text-field>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" class="px-8">
                                        <div class="d-flex">
                                            <div class="mt-2 mr-4 text-h6" style="font-family: Saira Condensed, sans-serif; color: #0FAF79;">PASSWORD:</div>
                                        <v-text-field
                                        v-model="password"
                                        :rules="[formRules.required]"
                                        variant="solo"
                                        :append-inner-icon="showPass ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                                        :type="showPass ? 'text' : 'password'"
                                        @click:append-inner="showPass = !showPass"
                                        bg-color="#FFFFFF"
                                        class="text-h6"
                                        style="font-family: Saira Condensed, sans-serif;"
                                        density="compact"
                                        >
                                        </v-text-field>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" class="text-center">
                                        <v-btn
                                        @click="dologin()"
                                        :loading="loading"
                                        color="#0FAF79"
                                        height="40px"
                                        class="text-h6"
                                        style="font-family: Saira Condensed, sans-serif;"
                                        >
                                            Login
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-form>
                </v-col>
            </v-row>
            <v-row class="d-flex justify-center align-center">
                <v-col cols="12">
                    <div class="mt-4 text-subtitle-1 text-center" style="font-family: Angkor, serif; color: white;">IF YOU ARE A USER, PLEASE VISIT <a href="https://www.seham.tv" style="text-decoration-line: none;"><span style="color: #0FAF79;">SEHAM.TV</span></a></div>
                </v-col>
            </v-row>
        </v-container>
    </section>
    <v-snackbar
        v-model="snackbar"
        :timeout="2500"
        color="secondary"
        location="top">
        <v-icon>mdi-alert-circle</v-icon>
        <span class="ml-2">{{ snackText }}</span>
    </v-snackbar>
</template>

<script>    
import axios from 'axios';
import { defineComponent } from 'vue';
export default defineComponent({
    data() {
        return {
            username: null,
            password: null,
            snackbar: false,
            snackText: null,
            showPass: false,
            loading: false,
        }
    },  
    methods: {
        dologin() {
            if (this.$refs.Formref.validate()) {
                this.loading = true
                let data = {
                    username: this.username,
                    password: this.password
                };
                axios.post(process.env.VUE_APP_SERVER_URL + '/api/admin/auth/adminLogin', data, 
                { headers: 
                    { 
                     'Content-Type': 'application/json',
                     'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                    }
                } 
                ).then((res)=>{
                   this.loading = false
                   this.snackbar = true
                   this.snackText ='Logging in ...'
                   localStorage.setItem("seham-admin-accesstoken", res.data.accessToken)
                   this.$store.commit('UPDATE_USER', res.data.user)
                   window.location.reload()
                }).catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            this.snackbar = true
                            this.snackText ='Wrong Credentials'
                            this.loading = false
                        } else {
                            console.log(`Error: ${error.response.status}`);
                            if(error.response.status == 400){
                                this.snackbar = true
                                this.snackText ='Wrong Credentials'
                                this.loading = false
                            }
                        }
                    } 
                    else if (error.request) {
                        console.log('No response received', error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
            }
        },
        redirectToGoogleAuth() {
        // Redirect the user to the Google OAuth2.0 authentication URL
        window.location.href = this.googleAuthURL;
        },
    },
})
</script>

<style  scoped>
@import url('https://fonts.googleapis.com/css2?family=Angkor&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Saira+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap');

.login-bg {
	background-image: url('../assets/hero-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
    
    height: 100vh;
}
</style>