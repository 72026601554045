<template>
    <v-container class="toggle animated" fluid>
      <v-row dense>
        <v-col cols="12" md="4">
          <v-text-field
              v-model="search"
              variant="outlined"
              prepend-inner-icon="mdi-magnify"
              label="Search"
              density="comfortable"
              solo
              hide-details
              class="rounded-lg"
              color="white"
              style="font-family: Saira Condensed, sans-serif;"></v-text-field>
        </v-col>
        <!-- <v-col class="text-right" cols="12" md="8">
            <v-btn
            prepend-icon="mdi-plus"
            class="mt-2 rounded-lg"
            color="primary"
            height="40px"
            elevation="2" style="font-family: Saira Condensed, sans-serif;">
                Add Points on User
            </v-btn>
        </v-col> -->
        <v-col class="text-right" cols="12" md="8">
            <v-btn
            @click="showPointsTransacttion()"
            prepend-icon="mdi-eye"
            class="mt-2 rounded-lg"
            color="primary"
            height="40px"
            elevation="2" style="font-family: Saira Condensed, sans-serif;">
                View Points Transaction History
            </v-btn>
        </v-col>
        <v-col cols="12">
          <v-card class="dt-container mt-2">    
              <v-data-table
                :mobile="isMobileOrTablet()"
                :disable-sort="false"
                :headers="headers"
                :items="data"
                :search="search"
                :loading="loading"
                class="text-subtitle-1"
                style="font-family: Saira Condensed, sans-serif;">
              <template v-slot:loading>
                <v-progress-linear indeterminate color="primary" height="5" class="mt-1 rounded-lg"></v-progress-linear>
                <!-- <v-skeleton-loader type="table-row@10"></v-skeleton-loader> -->
              </template>
              <template v-slot:item.isVerified="{ item }">
                <v-chip v-if="item.isVerified == true" color="green">
                    <span>✔</span>
                </v-chip>
                <v-chip v-else color="red">
                    <span>✘</span>
                </v-chip>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                    <v-icon
                      @click="getUserPoints(item)"
                      class="me-2"
                      size="small"
                  >
                      mdi-text-box-check-outline
                    </v-icon>
              </template>
              </v-data-table>
          </v-card>
        </v-col>
      </v-row>
  </v-container>

  <v-dialog v-model="pointsDialog" width="450" persistent>
        <v-card :loading="pointsLoading" color="primary" rounded="lg" class="pa-2" style="font-family: Saira Condensed, sans-serif;">
            <v-card-text v-if="pointsLoading == false" class="font-weight-bold">
                "{{ user }}" is having '{{ points }}' seham points
            </v-card-text>
            <template v-slot:actions>
            <v-spacer></v-spacer>

            <v-btn @click="pointsDialog = false;" variant="outlined">
                Close
            </v-btn>
            </template>
        </v-card>
    </v-dialog>

    <PointsTransactionDialog ref="PointsTransactionDialog"/>
</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue';
import PointsTransactionDialog from '../Dialogs/Views/PointsTransactionDialog.vue';

export default defineComponent({
    components: {
        PointsTransactionDialog
    },
    data(){
        return {
            data: [],
            pointsDialog: false,
            pointsLoading: false,
            user: null,
            points: null,
            search: null,
            loading: false,
            headers: [
                { title: 'User', align: 'start', sortable: true, key: 'username' },
                { title: 'Discord ID', align: 'start', sortable: true, key: 'discordId' },
                { title: 'Kick', align: 'start', sortable: true, key: 'kick' },
                { title: 'Stake', align: 'start', sortable: true, key: 'stake' },
                { title: 'Is Verified', align: 'start', sortable: true, key: 'isVerified' },
                { title: 'Action', align: 'end', sortable: false, key: 'action' },
            ],
        }
    },
    methods: {
        init(){
            const token = localStorage.getItem('seham-admin-accesstoken')
            if(token){
                this.loading = true
                axios.get(process.env.VUE_APP_SERVER_URL + '/api/admin/user',
                { headers: 
                    { 
                        'Authorization': `Bearer ${token}`,
                        'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                    }
                }
                ).then((res)=>{
                    if(res){
                        this.data = res.data.users
                        this.loading = false
                    }
                }).catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                                localStorage.removeItem("vuex");
                                localStorage.removeItem("seham-admin-accesstoken");
                                window.location.reload();
                        } else {
                            console.log(`Error: ${error.response.status}`);
                        }
                    } 
                    else if (error.request) {
                        console.log('No response received', error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
            }
            else{
                localStorage.removeItem("vuex");
                localStorage.removeItem("seham-admin-accesstoken");
                window.location.reload();
            }
        },
        getUserPoints(data){
            const token = localStorage.getItem('seham-admin-accesstoken')
            this.pointsLoading = true
            this.pointsDialog = true
            axios.get(process.env.VUE_APP_SERVER_URL + '/api/admin/user/points/' + data._id,
            { headers: 
                { 
                    'Authorization': `Bearer ${token}`,
                    'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                }
            }
            ).then((res)=>{
                if(res){
                    this.points = res.data.points
                    this.user = data.username
                    this.pointsLoading = false
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                            localStorage.removeItem("vuex");
                            localStorage.removeItem("seham-admin-accesstoken");
                            window.location.reload();
                    } else {
                        console.log(`Error: ${error.response.status}`);
                    }
                } 
                else if (error.request) {
                    console.log('No response received', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        },
        showPointsTransacttion(){
            this.$refs.PointsTransactionDialog.initView()
        }
    },
    mounted(){
        this.init()
    }
}) 
</script>

<style scoped>

</style>