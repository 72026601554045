<template>
    <v-container class="toggle animated" fluid>
        <v-row dense>
            <v-col cols="12" md="4">
                <v-text-field
                    v-model="search"
                    variant="outlined"
                    prepend-inner-icon="mdi-magnify"
                    label="Search"
                    density="comfortable"
                    solo
                    hide-details
                    class="rounded-lg"
                    color="white"
                    style="font-family: Saira Condensed, sans-serif;">
                </v-text-field>
            </v-col>
            <v-col class="text-right" cols="12" md="8">
                <v-btn
                prepend-icon="mdi-plus"
                class="mt-2 rounded-lg"
                color="primary"
                height="40px"
                elevation="2" style="font-family: Saira Condensed, sans-serif;">
                    Add Roullete Giveaways
                </v-btn>
            </v-col>
            <v-col cols="12" class="mt-2">
                <v-row dense>
                    <v-col cols="12" md="3">
                        <v-card elevation="3" color="primary" class="pa-3">
                            <div>
                                <div class="text-subtitle-1 font-weight-bold" style="font-family: Saira Condensed, sans-serif;">Code:</div>
                                <div class="text-subtitle-1" style="font-family: Saira Condensed, sans-serif;">BallyboyGiveaway</div>
                            </div>
                            <div class="mt-4">
                                <div class="text-subtitle-1 font-weight-bold" style="font-family: Saira Condensed, sans-serif;">Status:</div>
                                <div class="text-subtitle-1" style="font-family: Saira Condensed, sans-serif;">Waiting to start</div>
                            </div>
                            <div class="mt-4 ga-3">
                                <v-btn elevation="4" class="text-subtitle-2" color="primary" style="font-family: Zen Dots, system-ui;">
                                    Start Entries
                                </v-btn>
                                <v-btn elevation="4" class="text-subtitle-2 mt-2" color="primary" style="font-family: Zen Dots, system-ui;">
                                    Stop Entries
                                </v-btn>
                                <v-btn @click="showWheels()" elevation="4" class="text-subtitle-2 mt-2" color="primary" style="font-family: Zen Dots, system-ui;">
                                    Spin the Wheel
                                </v-btn>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>

    <WheelOfNamesDialog ref="WheelOfNamesDialog" v-on:refresh="init()" />
</template>

<script>
import { defineComponent } from 'vue'
import WheelOfNamesDialog from '../Dialogs/WheelOfNamesDialog.vue';

export default defineComponent({
    components: {
        WheelOfNamesDialog
    },
    data(){
        return{
            search: null,
        }
    },
    methods: {
        showWheels(){
            this.$refs.WheelOfNamesDialog.initWheels()
        }
    }
})
</script>

<style scoped>

</style>